<template>
	<div class="columns">
		<div
			v-if="!$store.getters['ui/isLoading']"
			class="column is-10-tablet is-offset-1-tablet is-8-fullhd is-offset-2-fullhd"
		>
			<Title>Portal do Estudante</Title>

			<div class="columns is-multiline m-0 app-highlight">
				<Anchor
					:js-function="true"
					:target="getAcessoUlife.target || '_self'"
					:class="{
						'is-blocked': hasBloqueioDisciplinas
					}"
					class="column is-one-fifth-desktop is-4-tablet is-5-mobile app-card ulife"
					@click="redirectUlife()"
					@mouseover.native="mostraAjuda('ulife', null, isMobile, false)"
					@mouseleave.native="fechaAjuda('ulife')"
				>
					<Icon
						:file="getAcessoUlife.icon"
						size="60"
						class="app-card-icon"
					/>

					<span class="app-card-title">{{ getAcessoUlife.label }}</span>

					<Icon
						v-if="hasBloqueioDisciplinas"
						class="modal-block-icon has-text-centered mt-2"
						file="warning"
						size="1.5rem"
					/>

					<div
						v-if="hasBloqueioDisciplinas"
						class="ajuda ajuda-ulife"
					>
						<div class="ajuda-content">
							<div>
								Você possui disciplinas bloqueadas.<br>Regularize suas pendências para<br>voltar aos seus estudos.
							</div>
						</div>
					</div>
				</Anchor>

				<Anchor
					:href="getAcessoPagamentos.href"
					:target="get(getAcessoPagamentos, 'target', '_self')"
					class="column is-one-fifth-desktop is-4-tablet is-5-mobile app-card"
				>
					<Icon
						:file="getAcessoPagamentos.icon"
						:size="24"
						class="app-card-icon"
					/>

					<span class="app-card-title">{{ getAcessoPagamentos.label }}</span>

					<div class="app-card-item-wrapper">
						<span class="app-card-item">
							<b-skeleton
								v-if="pagamentosIsLoading"
								width="8rem"
								animated
							/>

							<template v-else>
								<b-icon
									:class="pagamentosAVencerClass"
									icon="circle"
									size="is-small"
								/>

								{{ pagamentosAVencer }} a vencer
							</template>
						</span>

						<span class="app-card-item">
							<b-skeleton
								v-if="pagamentosIsLoading"
								width="8rem"
								animated
							/>

							<template v-else>
								<b-icon
									:class="pagamentosEmAtrasoClass"
									icon="circle"
									size="is-small"
								/>

								{{ pagamentosEmAtraso }} em atraso
							</template>
						</span>
					</div>
				</Anchor>

				<Anchor
					:href="getAcessoDocumentos.href"
					:target="get(getAcessoDocumentos, 'target', '_self')"
					class="column is-4-desktop is-6-tablet is-12-mobile app-card"
				>
					<Icon
						:file="getAcessoDocumentos.icon"
						:size="24"
						class="app-card-icon"
					/>

					<span class="app-card-title">{{ getAcessoDocumentos.label }}</span>

					<div class="app-card-item-wrapper is-split-half">
						<span class="app-card-item">
							<b-skeleton
								v-if="documentosIsLoading"
								width="8rem"
								animated
							/>

							<template v-else>
								<b-icon
									:class="documentosNaoEnviadosClass"
									icon="circle"
									size="is-small"
								/>

								{{ documentosNaoEnviados }} não enviado{{
									documentosNaoEnviados != 1 ? 's' : ''
								}}
							</template>
						</span>

						<span class="app-card-item">
							<b-skeleton
								v-if="documentosIsLoading"
								width="8rem"
								animated
							/>

							<template v-else>
								<b-icon
									:class="documentosInvalidadosClass"
									icon="circle"
									size="is-small"
								/>

								{{ documentosInvalidados }} invalidado{{
									documentosInvalidados != 1 ? 's' : ''
								}}
							</template>
						</span>
					</div>
				</Anchor>
			</div>

			<h3 class="title is-4 mx-5 mt-6">
				Serviços
			</h3>

			<div
				v-if="getAcessos != undefined"
				class="columns is-multiline m-0 app-services"
			>
				<Anchor
					v-if="permiteVisualizarExperiencias"
					:href="
						isMobile && getAcessoExperiencias.ajuda.trim() != '' ? targetNull() : getAcessoExperiencias.href
					"
					:target="get(getAcessoExperiencias, 'target', '_self')"
					class="column is-full-mobile is-one-third app-card exp-card"
					@mouseover.native="
						getAcessoExperiencias.ajuda.trim() != ''
							? mostraAjuda('acesso-experiencias', getAcessoExperiencias.href, isMobile)
							: null
					"
					@mouseleave.native="fechaAjuda('acesso-experiencias')"
				>
					<div class="exp-bell" v-if="porcentagem < 100">
						<Icon
							file="bell"
							:size="16"
						/>
					</div>

					<div class="ajuda ajuda-acesso-experiencias">
						<div class="ajuda-content">
							<div>
								{{ getAcessoExperiencias.ajuda }}
							</div>
						</div>
					</div>

					<Icon
						:file="getAcessoExperiencias.icon"
						size="24"
						class="app-card-icon"
					/>
					<div class="exp-card-progress">
						<div class="exp-title">
							<span class="app-card-title">{{ getAcessoExperiencias.label }}</span>
							<span class="app-card-title">{{ porcentagem }}%</span>
						</div>
						<b-progress
							:show-value="false"
							:value="porcentagem"
							size="is-small"
							type="is-success"
						/>
					</div>
				</Anchor>

				<Anchor
					v-for="(acesso, index) in getAcessos"
					:key="index"
					:href="
						isMobile && acesso.ajuda.trim() != '' ? targetNull() : acesso.href
					"
					:target="get(acesso, 'target', '_self')"
					class="column is-full-mobile is-one-third app-card"
					@mouseover.native="
						acesso.ajuda.trim() != ''
							? mostraAjuda(index, acesso.href, isMobile)
							: null
					"
					@mouseleave.native="fechaAjuda(index)"
				>
					<div :class="'ajuda ajuda-' + index">
						<div class="ajuda-content">
							<div>
								{{ acesso.ajuda }}
							</div>
						</div>
					</div>

					<Icon
						:file="acesso.icon"
						size="24"
						class="app-card-icon"
					/>

					<span class="app-card-title">{{ acesso.label }}</span>
				</Anchor>

				<Anchor
					v-if="permiteVisualizarAtividadesComplementares"
					:href="
						isMobile && getAcessoAtividadesComplementares.ajuda.trim() != ''
							? ''
							: permiteAcessarAtividadesComplementares
								? getAcessoAtividadesComplementares.href
								: ''
					"
					:target="
						permiteAcessarAtividadesComplementares
							? get(getAcessoAtividadesComplementares, 'target', '_self')
							: ''
					"
					class="column is-full-mobile is-one-third app-card"
					@click.native="verificaAcessarAtividadesComplementares"
					@mouseover.native="
						getAcessoAtividadesComplementares.ajuda.trim() != ''
							? mostraAjuda(
								indexAtividadesComplementares,
								getAcessoAtividadesComplementares.href,
								isMobile,
								permiteAcessarAtividadesComplementares
							)
							: null
					"
					@mouseleave.native="fechaAjuda(indexAtividadesComplementares)"
				>
					<div :class="'ajuda ajuda-' + indexAtividadesComplementares">
						<div class="ajuda-content">
							<div>
								{{ getAcessoAtividadesComplementares.ajuda }}
							</div>
						</div>
					</div>

					<Icon
						:file="getAcessoAtividadesComplementares.icon"
						:size="24"
						class="app-card-icon disable-anchor"
					/>

					<span class="app-card-title disable-anchor">{{
						getAcessoAtividadesComplementares.label
					}}</span>
				</Anchor>

				<Anchor
					v-if="permiteVisualizarAcessoCertificadoPosDigital"
					:href="
						isMobile && getAcessoCertificadoPosDigital.ajuda.trim() != ''
							? ''
							: permiteAcessarCertificadoPosDigital
								? getAcessoCertificadoPosDigital.href
								: ''
					"
					:target="
						permiteAcessarCertificadoPosDigital
							? get(getAcessoCertificadoPosDigital, 'target', '_self')
							: ''
					"
					class="column is-full-mobile is-one-third app-card"
					@click.native="verificaAcessarCertificadoPos"
					@mouseover.native="
						getAcessoCertificadoPosDigital.ajuda.trim() != ''
							? mostraAjuda(
								indexCertificadoPos,
								getAcessoCertificadoPosDigital.href,
								isMobile,
								permiteAcessarCertificadoPosDigital
							)
							: null
					"
					@mouseleave.native="fechaAjuda(indexCertificadoPos)"
				>
					<div :class="'ajuda ajuda-' + indexCertificadoPos">
						<div class="ajuda-content">
							<div>
								{{ getAcessoCertificadoPosDigital.ajuda }}
							</div>
						</div>
					</div>

					<Icon
						:file="getAcessoCertificadoPosDigital.icon"
						:size="24"
						class="app-card-icon disable-anchor"
					/>

					<span class="app-card-title disable-anchor">{{
						getAcessoCertificadoPosDigital.label
					}}</span>
				</Anchor>
			</div>
		</div>

		<FirstAccess />

		<ModalBloqueio
			v-if="showModalBloqueio"
			@ciente="cienteBloqueio"
			@close="showModalBloqueio = false"
		/>

		<b-modal
			v-model="openModalCertificados"
			:destroy-on-hide="false"
			aria-role="dialog"
			close-button-aria-label="Close"
			aria-modal
		>
			<CertificadoPosDigital />
		</b-modal>
	</div>
</template>

<script>
import { get } from 'lodash';

import { mapActions, mapGetters, mapState } from 'vuex';

import Anchor from '@components/Anchor';
import CertificadoPosDigital from '@pages/portal/CertificadoPosDigital';
import FirstAccess from '@components/FirstAccess';
import Icon from '@components/Icon';
import ModalBloqueio from '@components/home/ModalBloqueio';
import Title from '@components/Title';

export default {
	name: 'Home',

	components: {
		Anchor,
		CertificadoPosDigital,
		FirstAccess,
		Icon,
		ModalBloqueio,
		Title,
	},

	data() {
		return {
			showModalBloqueio: false,
		};
	},

	computed: {
		...mapState({
			pagamentosIsLoading: state => state.pagamentos.isLoading,
			pagamentosAVencer: state => state.pagamentos.abertos.length,
			pagamentosEmAtraso: state => state.pagamentos.atrasados.length,
			documentosIsLoading: state => state.documentos.isLoading,
			documentosInvalidados: state => state.documentos.invalidados.length,
			documentosNaoEnviados: state => state.documentos.naoEnviados.length
		}),

		...mapGetters('aluno/certificados', [
			'openModal'
		]),

		...mapGetters('cursos', [
			'getExistePosFormado'
		]),

		...mapGetters('vinculosAcademicos', [
			'getVinculoSelecionado'
		]),

		...mapGetters('servicos', [
			'getAcessos',
			'getAcessoUlife',
			'getAcessoExperiencias',
			'getAcessoDocumentos',
			'getAcessoPagamentos',
			'getAcessoCertificadoPosDigital',
			'contemServicos',
			'permiteVisualizarExperiencias',
			'permiteAcessarCertificadoPosDigital',
			'permiteVisualizarAcessoCertificadoPosDigital',
			'permiteAcessarAtividadesComplementares',
			'permiteVisualizarAtividadesComplementares',
			'getAcessoAtividadesComplementares',
			'getUrlSso',
		]),

		...mapGetters('ui', [
			'isMobile'
		]),

		...mapGetters('sessao', [
			'hasBloqueioDisciplinas',
			'getBloqueioDisciplinas',
		]),

		...mapGetters('experiencias', [
			'contemDados',
		]),

		openModalCertificados: {
			get() {
				return this.openModal;
			},
			set(openModal) {
				this.$store.commit('aluno/certificados/openModal', openModal);
			}
		},

		pagamentosAVencerClass() {
			return this.pagamentosAVencer ? 'icone-amarelo' : 'icone-verde';
		},

		pagamentosEmAtrasoClass() {
			return this.pagamentosEmAtraso ? 'icone-vermelho' : 'icone-verde';
		},

		documentosNaoEnviadosClass() {
			return this.documentosNaoEnviados ? 'icone-vermelho' : 'icone-verde';
		},

		documentosInvalidadosClass() {
			return this.documentosInvalidados ? 'icone-vermelho' : 'icone-verde';
		},

		indexAtividadesComplementares() {
			return this.getAcessos.length + 1;
		},

		indexCertificadoPos() {
			return this.getAcessos.length + 2;
		},

		total() {
			return this.$store.getters['experiencias/getCargaHorariaTotal'] || 0;
		},

		cursada() {
			return this.$store.getters['experiencias/getCargaHorariaCursada'] || 0;
		},

		porcentagem() {
			const fraction = this.cursada / this.total;

			return Math.floor((fraction || 0) * 100);
		},
	},

	async created() {
		if (!this.$store.getters['sessao/isLogged']) {
			return;
		}

		if (!this.contemServicos) {
			this.todosServicos();
		}

		if (!this.contemDados) {
			this.todasExperiencias();
		}

		this.$store.dispatch('documentos/todosDocumentos');
		this.$store.dispatch('pagamentos/todosPagamentos');
	},

	methods: {
		...mapActions('servicos', [
			'todosServicos',
			'callSsoUrl'
		]),

		...mapActions('ui', [
			'loading'
		]),

		...mapActions('experiencias', [
			'todasExperiencias',
		]),

		get,

		verificaAcessarCertificadoPos() {
			if (!this.permiteAcessarCertificadoPosDigital) {
				this.$buefy.snackbar.open({
					message:
						'Selecione um curso com status "Formado" no filtro de vinculo acima!',
					type: 'is-warning',
					position: 'is-bottom',
					actionText: null,
					indefinite: false,
					duration: 3500
				});
			}
		},

		verificaAcessarAtividadesComplementares() {
			if (!this.permiteAcessarAtividadesComplementares) {
				this.$buefy.snackbar.open({
					message:
						'Selecione um curso de graduação para acessar a página de Atividades Complementares!',
					type: 'is-warning',
					position: 'is-bottom',
					actionText: null,
					indefinite: false,
					duration: 3500
				});
			}
		},

		mostraAjuda(index, url, isMobile, canRedirect = true) {
			document.querySelectorAll('.ajuda-' + index).forEach(el => {
				el.style.display = 'block';
			});
			if (isMobile && canRedirect) {
				setTimeout(function () {
					window.location.href = url;
				}, 3000);
			}

		},

		fechaAjuda(index) {
			document.querySelectorAll('.ajuda-' + index).forEach(el => {
				el.style.display = 'none';
			});
		},

		targetNull() {
			return '#';
		},

		async redirectUlife(cienteBloqueio = false) {
			if (!cienteBloqueio && this.hasBloqueioDisciplinas) {
				this.showModalBloqueio = true;

				return;
			}

			this.loading(true);

			try {
				await this.callSsoUrl();

				const url = this.getUrlSso;

				if (String(url).length > 0) {
					await this.$store.dispatch('servicos/clearSsoUrl');

					window.open(url, '_blank');
				} else {
					throw new Error();
				}
			} catch {
				this.$buefy.snackbar.open({
					message: 'Erro ao acessar a sala virtual, tente novamente mais tarde!',
					type: 'is-warning',
					position: 'is-bottom',
					actionText: null,
					indefinite: false,
					duration: 3500,
				});
			}

			this.loading(false);
		},

		cienteBloqueio(){
			this.modalBloqueio = false;

			this.redirectUlife(true);
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

.app-highlight .app-card,
.app-services {
	padding: 1.5rem 0;
	background: #FFFFFF;
	border: 1px solid #C2C2C2;
	box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.15);
	border-radius: 6px;
}

.app-highlight .app-card.is-blocked {
	padding-bottom: 0;
}

.app-card {
	.app-card-icon {
		background-color: $white;
		background-color: var(--white);
		width: 54px;
		height: 54px;
		line-height: 1;
		border-radius: 100%;
		display: flex;
		flex: 0 0 auto;
		justify-content: center;
		align-items: center;
		transition: all 300ms ease 0ms;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
			0px 0px 6px rgba(0, 0, 0, 0.05);
	}

	&:hover .app-card-icon {
		background-color: $gray-200;
		background-color: var(--gray-200);
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 6px rgba(0, 0, 0, 0.25);
	}

	.app-card-title {
		margin-top: 0.25rem;
		display: inline-block;
		color: #173048;
		font-weight: 700;
		white-space: pre-line;
	}

	.app-card-item {
		padding: 0;
		font-size: 0.875rem;
		color: #525252;
		font-weight: 700;
	}
}

.exp-bell {
	align-content: center;
	background-color: white;
	border-radius: 50%;
	box-shadow: 0px 4px 4px 0px #00000040;
	height: 2rem;
	right: -10px;
	text-align: center;
	position: absolute;
	top: -10px;
	width: 2rem;
}

.exp-card {
	background-color: var(--primary-hover);
	border-radius: 1rem;
	position: relative;
	width: 240px;
}

::v-deep {
	.is-success.progress-wrapper.is-not-native::-webkit-progress-value,
	.progress.is-success::-webkit-progress-value {
		background-color: var(--primary)
	}

	.is-success.progress-wrapper.is-not-native::-moz-progress-bar,
	.progress.is-success::-moz-progress-bar {
		background-color: var(--primary)
	}

	.is-success.progress-wrapper.is-not-native::-ms-fill,
	.progress.is-success::-ms-fill {
		background-color: var(--primary)
	}

	.is-success.progress-wrapper.is-not-native:indeterminate,
	.progress.is-success:indeterminate {
		background-image: linear-gradient(90deg, var(--primary) 30%, #ededed 0)
	}
}

.exp-card .app-card-title {
	color: white!important;
}

.exp-title {
	display: flex;
}

.progress-wrapper {
	margin: 8px 0px 0px 16px;
	width: 90%;
}

.app-highlight {
	justify-content: center;
	gap: 2rem;
	display: flex;

	.app-card {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 0 0 auto;

		.app-card-icon {
			width: 48px;
			height: 48px;
			box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.15);
		}

		&:hover .app-card-icon {
			box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.25);
		}

		&.ulife .app-card-icon {
			width: 100px;
			height: 100px;
			background: linear-gradient(
				125.9deg,
				#48d6b4 12.65%,
				#06cef0 47.84%,
				#28a1fd 85.82%,
				#1f80e1 192.51%
			);
			box-shadow: none !important;
		}

		.app-card-title {
			max-width: 10rem;
			text-align: center;
		}

		&.ulife:hover .icon-wrapper {
			background-color: $primary-hover;
			background-color: var(--primary-hover);
		}

		.app-card-item-wrapper {
			margin: 0.5rem auto 0;
			display: flex;
			flex-direction: column;
			gap: 0.5rem 0;

			&.is-split-half {
				padding: 0 1rem;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;

				> span {
					margin: 0 0.5rem;
				}
			}
		}
	}
}

.app-services {
	padding: 1.5rem 5rem;

	.app-card {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.app-card-title {
			margin-left: 1rem;
		}
	}
}

// mobile only .
@media screen and (max-width: 768px) {
	.app-highlight .app-card .app-card-item-wrapper.is-split-half > span {
		padding: 0;
	}

	.app-services {
		padding: 1rem;
	}

	.ajuda {
		position: absolute;
		margin-bottom: 13rem;
		margin-right: 0.6rem;
		display: none;
	}

	.ajuda-content {
		max-height: 18rem;
	}
}

// tablet only
@media screen and (min-width: 769px) and (max-width: 1023px) {
	.app-highlight .app-card .app-card-item-wrapper.is-split-half > span {
		padding: 0 0.25rem;
	}

	.app-services {
		padding: 1rem;
	}
}

// desktop only
@media screen and (min-width: 1024px) and (max-width: 1215px) {
	.app-highlight .app-card .app-card-item-wrapper.is-split-half > span {
		padding-left: 0 0.125rem;
	}

	.app-services {
		padding: 1.5rem 2rem;
	}
}

// desktop +
@media screen and (min-width: 1024px) {
	.app-highlight .app-card .app-card-item-wrapper.is-split-half {
		flex-direction: column;
	}
}

.icone-verde {
	color: #119737 !important;
}

.icone-amarelo {
	color: #ffb100 !important;
}

.icone-vermelho {
	color: #ff1515 !important;
}

.ajuda {
	position: absolute;
	margin-bottom: 13rem;
	display: none;
}

.ajuda-content {
	color: #454545;
	background-color: #d0d1d4;
	position: relative;
	padding: 1rem;
	border-radius: 0.3rem;
	max-width: 27.1rem;
	font-size: 0.9rem;
	min-height: 6rem;
	max-height: 9rem;
	overflow: hidden;
}
.ajuda:after {
	content: '';
	width: 0;
	height: 0;
	position: absolute;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-top: 11px solid #d0d1d4;
	left: 16px;
}
.ajuda-ulife {
	&.ajuda {
    margin-bottom: 0;
		margin-top: 11rem;
	}
	.ajuda-content {
    color: #ffffff;
    background-color: #ff0000;
	}
&.ajuda::after {
		border-width: 0;
}
&.ajuda::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 11px solid #ff0000;
    display: block;
    margin: 0 auto;
	}
}
::v-deep {
.modal-block-icon {
		svg {
			vertical-align: middle;
			position: relative;
			bottom: 0.3rem;
		}
		svg * {
			fill: #FF1515;
		}
	}
      }
</style>
