<template>
	<b-modal
		v-if="!$store.getters['ui/isLoading']"
		v-model="isFirstAccess"
		class="modal-welcome"
		:can-cancel="false"
	>
		<div class="card">
			<div class="card-content">
				<h3 class="title is-3 has-text-centered">
					Receba nossas boas-vindas!
				</h3>

				<p class="paragraph mb-5">
					Este portal é o seu apoio e te dá acesso à sua disciplina. Além
					disso, aqui você também pode:
				</p>

				<div class="columns">
					<div class="column is-narrow has-text-centered">
						<UIImage
							class="image-welcome"
							size="1:1"
							bgsize="contain"
							:src="'study-2.svg'"
							alt=""
						/>
					</div>

					<div class="column list-pending">
						<p>
							<svg
								class="arrow-list-pending"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
									class="arrow-list-pending-color"
								/>

								<path
									d="M14.5858 12L11.2929 8.70711C10.9024 8.31658 10.9024 7.68342 11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071C10.9024 16.3166 10.9024 15.6834 11.2929 15.2929L14.5858 12Z"
									class="arrow-list-pending-color"
								/>

								<path
									d="M8 13C7.44771 13 7 12.5523 7 12C7 11.4477 7.44771 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H8Z"
									class="arrow-list-pending-color"
								/>
							</svg>
							Enviar documentação pendente
						</p>

						<p>
							<svg
								class="arrow-list-pending"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
									class="arrow-list-pending-color"
								/>

								<path
									d="M14.5858 12L11.2929 8.70711C10.9024 8.31658 10.9024 7.68342 11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071C10.9024 16.3166 10.9024 15.6834 11.2929 15.2929L14.5858 12Z"
									class="arrow-list-pending-color"
								/>

								<path
									d="M8 13C7.44771 13 7 12.5523 7 12C7 11.4477 7.44771 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H8Z"
									class="arrow-list-pending-color"
								/>
							</svg>
							Fazer solicitações acadêmicas e cadastrar horas complementares
						</p>

						<p>
							<svg
								class="arrow-list-pending"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
									class="arrow-list-pending-color"
								/>

								<path
									d="M14.5858 12L11.2929 8.70711C10.9024 8.31658 10.9024 7.68342 11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071C10.9024 16.3166 10.9024 15.6834 11.2929 15.2929L14.5858 12Z"
									class="arrow-list-pending-color"
								/>

								<path
									d="M8 13C7.44771 13 7 12.5523 7 12C7 11.4477 7.44771 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H8Z"
									class="arrow-list-pending-color"
								/>
							</svg>
							Solicitar histórico escolar e comprovante de matrícula
						</p>

						<p>
							<svg
								class="arrow-list-pending"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
									class="arrow-list-pending-color"
								/>

								<path
									d="M14.5858 12L11.2929 8.70711C10.9024 8.31658 10.9024 7.68342 11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071C10.9024 16.3166 10.9024 15.6834 11.2929 15.2929L14.5858 12Z"
									class="arrow-list-pending-color"
								/>

								<path
									d="M8 13C7.44771 13 7 12.5523 7 12C7 11.4477 7.44771 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H8Z"
									class="arrow-list-pending-color"
								/>
							</svg>
							Ter acesso ao seu histórico de pagamento e ao próximo boleto a
							vencer
						</p>
					</div>
				</div>

				<p class="paragraph">
					Agora que você já sabe algumas das coisas que você pode fazer aqui,
					explore por ai!
				</p>

				<div class="columns">
					<div class="column has-text-centered">
						<b-button
							type="is-primary"
							class="mt-5"
							rounded
							@click="start"
						>
							Vamos começar?
						</b-button>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import http from '@commons/http';
import UIImage from '@components/UIImage';

export default {
	name: 'FirstAccess',

	components: {
		UIImage,
	},

	data() {
		return {
			isFirstAccess: false,
		};
	},

	created() {
		this.getFirstAccess();
	},

	methods: {
		async getFirstAccess() {
			this.$store.dispatch('ui/loading', true);

			try {
				const data = (await http.get('/v1/aluno/first-access')).data;

				this.isFirstAccess = data.message;
			} finally {
				this.$store.dispatch('ui/loading', false);
			}
		},

		start() {
			this.isFirstAccess = false;

			http.post('/v1/aluno/first-access-done');
		},
	}
}
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

.modal-welcome {
	.modal-content {
		max-width: 638px !important;
		width: calc(100% - 20px);

		.card-content {
			padding: 40px;
		}
	}
}

.card-content {
	.list-pending {
		.arrow-list-pending {
			position: absolute;
			top: 0;
			left: 0;

			&-color {
				fill: $primary;
				fill: var(--primary);
			}
		}

		p {
			margin-bottom: 12px;
			position: relative;
			padding-left: 35px;
		}
	}

	.image-welcome {
		width: 100vw;
		max-width: 128px;
	}
}
</style>
