<template>
	<b-modal
		v-if="!$store.getters['ui/isLoading']"
		active
		class="modal-bloqueio"
		:can-cancel="['x','escape']"
		@close="$emit('close')"

	>
		<div class="card">
			<div class="card-content">
					<Icon
						class="modal-info-icon has-text-centered"
						file="warning"
						size="8rem"

					/>
				<p class="has-text-centered paragraph mb-5 text-title">
					Você tem disciplinas Bloqueadas
				</p>
				<p class="paragraph mb-5 text-info">
					Para visualizar suas pendências financeiras ou documentais, acesse a seção
					pagamentos ou envio de documentos e regularize suas pendências para voltar
					a estudar.
				</p>

					<div class="column has-text-centered">
						<b-button
							type="is-primary"
							rounded
							@click="ciente"
						>
							Estou ciente
						</b-button>
					</div>
				</div>
			</div>
	</b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@components/Icon';


export default {
	name: 'ModalBloqueio',
	components: {
		Icon
	},
	computed:{
		...mapGetters('sessao', [
			'hasBloqueioDisciplinas',
			'getBloqueioDisciplinas',
		]),
	},
	methods: {
		ciente() {
			this.$emit('ciente');
		},
	},

}
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

::v-deep {
	.modal-content {
		max-width: 638px !important;
		width: calc(100% - 20px);

		.card-content {
			padding: 40px;
			.text-title {
				font-size: 2rem;
				color: #26496c;
				font-weight: bold;

			}
			.text-info {
				font-size: 1.125rem;
				text-align: center;
			}

		}
	}

  .modal-info-icon {
        svg {
          vertical-align: middle;
          position: relative;
          bottom: 0.3rem;
        }
        svg * {
          fill: #FF1515;
        }

      }
	}
</style>
